import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import useLoadLocale from './hooks/useLocale'
import Loading from './pages/Loading'
import AuthError from './pages/AuthError'
import { lazy } from './components/ReactLazyWithReload'

const App = lazy(() => import(/* webpackChunkName: "App" */ './App'))

const FamilyAlbumGiftCardFlow = lazy(
  () =>
    import(
      /* webpackChunkName: "FamilyAlbumGiftCardFlow" */ './pages/fa-gift-card-flow/FamilyAlbumGiftCardFlow'
    )
)

const FamilyAlbumGiftCardFlowRecipient = lazy(
  () =>
    import(
      /* webpackChunkName: "FamilyAlbumGiftCardFlowRecipient" */ './pages/fa-gift-card-flow/FamilyAlbumGiftCardFlowRecipient'
    )
)

const RecipientViewModal = lazy(
  () =>
    import(
      /* webpackChunkName: "RecipientViewModal" */ './pages/familyAlbumAppModals/RecipientViewModal'
    )
)

const DemoModal = lazy(
  () =>
    import(
      /* webpackChunkName: "DemoModal" */ './pages/familyAlbumAppModals/DemoModal'
    )
)

const SelfBirthday = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfBirthday" */ './pages/familyAlbumAppModals/SelfBirthday'
    )
)

const BirthdayEventModal = lazy(
  () =>
    import(
      /* webpackChunkName: "BirthdayEventModal" */ './pages/familyAlbumAppModals/BirthdayEventModal'
    )
)

const BirthdayTrackerLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BirthdayTrackerLandingPage" */ './pages/familyAlbumAppModals/BirthdayTrackerLandingPage'
    )
)

const NyeLandingPageModal = lazy(
  () =>
    import(
      /* webpackChunkName: "NyeLandingPageModal" */ './pages/familyAlbumAppModals/NyeLandingPageModal'
    )
)

const HolidayLandingPageModal = lazy(
  () =>
    import(
      /* webpackChunkName: "HolidayLandingPageModal" */ './pages/familyAlbumAppModals/HolidayLandingPageModal'
    )
)

const MothersDayDgcModal = lazy(
  () =>
    import(
      /* webpackChunkName: "MothersDayDgcModal" */ './pages/familyAlbumAppModals/MothersDayDgcModal'
    )
)

const UkMothersDayDgcModal = lazy(
  () =>
    import(
      /* webpackChunkName: "UkMothersDayDgcModal" */ './pages/familyAlbumAppModals/UkMothersDayDgcModal'
    )
)

const FathersDayModal = lazy(
  () =>
    import(
      /* webpackChunkName: "FathersDayModal" */ './pages/familyAlbumAppModals/FathersDayModal'
    )
)

const GrandparentsDayModal = lazy(
  () =>
    import(
      /* webpackChunkName: "GrandparentsDayModal" */ './pages/familyAlbumAppModals/GrandparentsDayModal'
    )
)

const OpenOnDeviceWithFaApp = lazy(
  () =>
    import(
      /* webpackChunkName: "OpenOnDeviceWithFaApp" */ './pages/OpenOnDeviceWithFaApp'
    )
)

const BabyMilestone1Modal = lazy(
  () =>
    import(
      /* webpackChunkName: "BabyMilestone1Modal" */ './pages/familyAlbumAppModals/BabyMilestone1Modal'
    )
)

const BabyMilestone3Modal = lazy(
  () =>
    import(
      /* webpackChunkName: "BabyMilestone3Modal" */ './pages/familyAlbumAppModals/BabyMilestone3Modal'
    )
)

const BabyMilestone6Modal = lazy(
  () =>
    import(
      /* webpackChunkName: "BabyMilestone6Modal" */ './pages/familyAlbumAppModals/BabyMilestone6Modal'
    )
)

const BabyMilestone9Modal = lazy(
  () =>
    import(
      /* webpackChunkName: "BabyMilestone9Modal" */ './pages/familyAlbumAppModals/BabyMilestone9Modal'
    )
)

const ThankYouModal = lazy(
  () =>
    import(
      /* webpackChunkName: "ThankYouModal" */ './pages/familyAlbumAppModals/ThankYouModal'
    )
)

const FamilyAlbumGiftCardInDigitaGifts = lazy(
  () =>
    import(
      /* webpackChunkName: "FamilyAlbumGiftCardInDigitaGifts" */ './pages/familyAlbumAppModals/FamilyAlbumGiftCardInDigitaGifts'
    )
)

const FamilyAlbumGiftCard = lazy(
  () =>
    import(
      /* webpackChunkName: "FamilyAlbumGiftCard" */ './pages/familyAlbumAppModals/FamilyAlbumGiftCard'
    )
)

const FamilyAlbumGiftCardOshiraseModal = lazy(
  () =>
    import(
      /* webpackChunkName: "FamilyAlbumGiftCardOshirase" */ './pages/familyAlbumAppModals/FamilyAlbumGiftCardOshirase'
    )
)

const ThanksgivingModal = lazy(
  () =>
    import(
      /* webpackChunkName: "ThanksgivingModal" */ './pages/familyAlbumAppModals/ThanksgivingModal'
    )
)

const fathersDayDgcLanding = 'fathers-day-dgc-landing'
const thanksgivingDgcLanding = 'thanksgiving-egifts-landing'
const grandparentsDayDgcLanding = 'grandparents-day-dgc-landing'
const modalHolidayCelebrateLanding = 'holiday2024_landing'
const modalNyeCelebrateLanding = 'celebrate-nye-landing-page'

const modalBirthdayTrackerLanding = 'modal-landing-page'

const modalThankYou = 'thank-you'
const modalDemoFlow = 'modal-demo-flow'

const modalSelfBirthdayNewUsersPath = 'modal-self-birthday-new-users'
const modalSelfBirthdayBPath = 'modal-self-birthday-b'
const modalRecipientView = 'recipient-view'
const modalMilestone1 = 'modal-milestone-1'
const modalMilestone3 = 'modal-milestone-3'
const modalMilestone6 = 'modal-milestone-6'
const modalMilestone9 = 'modal-milestone-9'

const Root = ({
  modalRedirectUrl,
  clarityProjectId,
  explanationVideoUrl,
  nyeExplanationVideoUrl,
  familyAlbumAutologinDeeplink,
  familyAlbumDownloadDeeplink,
  mothersDayExplanationVideoUrl,
  currentOccasions,
  fathersDayExplanationVideoUrl,
  familyAlbumStoreDeeplink,
}: {
  modalRedirectUrl: string
  testRoutesEnabled: boolean
  clarityProjectId: string
  explanationVideoUrl: string
  nyeExplanationVideoUrl: string
  familyAlbumAutologinDeeplink: string
  familyAlbumDownloadDeeplink: string
  mothersDayExplanationVideoUrl: string
  currentOccasions: string[]
  fathersDayExplanationVideoUrl: string
  holidayExplanationVideoUrl: string
  familyAlbumStoreDeeplink: string
}) => {
  useLoadLocale({
    version: import.meta.env.VITE_VERSION as string,
  })

  // If there are any event types currently supported other than onlt birthday, the c2a
  // button on the birthday tracker will redirect them to the occassion selection screen
  // rather than directly to create a new birthday celebration
  const areSpecialOccasionsLive = currentOccasions.length > 0

  return (
    <Routes>
      <Route
        path="fa-gift-card-modal"
        element={
          <Suspense fallback={<Loading />}>
            <FamilyAlbumGiftCard />
          </Suspense>
        }
      />
      <Route
        path="fa-gift-card-oshirase-modal"
        element={
          <Suspense fallback={<Loading />}>
            <FamilyAlbumGiftCardOshiraseModal />
          </Suspense>
        }
      />
      <Route
        path="fa-gift-card-sender/*"
        element={
          <Suspense fallback={<Loading />}>
            <FamilyAlbumGiftCardFlow
              familyAlbumDeeplink={familyAlbumStoreDeeplink}
              buttonValues={[25, 50, 100, 200]}
            />
          </Suspense>
        }
      />
      <Route
        path="fa-gift-card-recipient/:eGiftId"
        element={
          <Suspense fallback={<Loading />}>
            <FamilyAlbumGiftCardFlowRecipient
              familyAlbumStoreDeeplink={familyAlbumStoreDeeplink}
            />
          </Suspense>
        }
      />
      <Route
        path="modal-family-album-gift-card"
        element={
          <Suspense fallback={<Loading />}>
            <FamilyAlbumGiftCardInDigitaGifts
              modalRedirectUrl={modalRedirectUrl}
              c2a="Get started"
            />
          </Suspense>
        }
      />
      <Route
        path={modalDemoFlow}
        element={
          <Suspense fallback={<Loading />}>
            <DemoModal modalRedirectUrl={modalRedirectUrl} />
          </Suspense>
        }
      />
      <Route
        path={modalNyeCelebrateLanding}
        element={
          <Suspense fallback={<Loading />}>
            <NyeLandingPageModal
              c2a="Get started"
              explanationVideoUrl={nyeExplanationVideoUrl}
              modalRedirectUrl={modalRedirectUrl}
            />
          </Suspense>
        }
      />
      <Route
        path={modalHolidayCelebrateLanding}
        element={
          <Suspense fallback={<Loading />}>
            <HolidayLandingPageModal
              c2a="Get Started"
              modalRedirectUrl={modalRedirectUrl}
            />
          </Suspense>
        }
      />
      <Route
        path="mothers-day-dgc-landing"
        element={
          <Suspense fallback={<Loading />}>
            <MothersDayDgcModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={mothersDayExplanationVideoUrl}
            />
          </Suspense>
        }
      />
      <Route
        path="uk-mothers-day-dgc-landing"
        element={
          <Suspense fallback={<Loading />}>
            <UkMothersDayDgcModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={mothersDayExplanationVideoUrl}
            />
          </Suspense>
        }
      />
      <Route
        path={fathersDayDgcLanding}
        element={
          <Suspense fallback={<Loading />}>
            <FathersDayModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={fathersDayExplanationVideoUrl}
            />
          </Suspense>
        }
      />
      <Route
        path={grandparentsDayDgcLanding}
        element={
          <Suspense fallback={<Loading />}>
            <GrandparentsDayModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
            />
          </Suspense>
        }
      />
      <Route
        path="celebrate-birthday-landing-page"
        element={
          <Suspense fallback={<Loading />}>
            <BirthdayEventModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={explanationVideoUrl}
              redirectToOccasionsPage={false} // We already know the occasion
            />
          </Suspense>
        }
      />
      <Route
        path="modal-celebrate-landing-page"
        element={
          <Suspense fallback={<Loading />}>
            <BirthdayEventModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={explanationVideoUrl}
              redirectToOccasionsPage={areSpecialOccasionsLive}
            />
          </Suspense>
        }
      />
      <Route
        path={modalBirthdayTrackerLanding}
        element={
          <Suspense fallback={<Loading />}>
            <BirthdayTrackerLandingPage
              modalRedirectUrl={modalRedirectUrl}
              explanationVideoUrl={explanationVideoUrl}
            />
          </Suspense>
        }
      />
      <Route
        path={modalSelfBirthdayBPath}
        element={
          <Suspense fallback={<Loading />}>
            <SelfBirthday showOptOutOption />
          </Suspense>
        }
      />
      <Route
        path={modalRecipientView}
        element={
          <Suspense fallback={<Loading />}>
            <RecipientViewModal modalRedirectUrl={modalRedirectUrl} />
          </Suspense>
        }
      />
      <Route
        path={modalSelfBirthdayNewUsersPath}
        element={
          <Suspense fallback={<Loading />}>
            <SelfBirthday />
          </Suspense>
        }
      />
      <Route
        path={modalMilestone1}
        element={
          <Suspense fallback={<Loading />}>
            <BabyMilestone1Modal />
          </Suspense>
        }
      />
      <Route
        path={modalMilestone3}
        element={
          <Suspense fallback={<Loading />}>
            <BabyMilestone3Modal />
          </Suspense>
        }
      />
      <Route
        path={modalMilestone6}
        element={
          <Suspense fallback={<Loading />}>
            <BabyMilestone6Modal />
          </Suspense>
        }
      />
      <Route
        path={modalMilestone9}
        element={
          <Suspense fallback={<Loading />}>
            <BabyMilestone9Modal />
          </Suspense>
        }
      />
      <Route
        path={modalThankYou}
        element={
          <Suspense fallback={<Loading />}>
            <ThankYouModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
            />
          </Suspense>
        }
      />
      <Route
        path={thanksgivingDgcLanding}
        element={
          <Suspense fallback={<Loading />}>
            <ThanksgivingModal
              c2a="Get started"
              modalRedirectUrl={modalRedirectUrl}
            />
          </Suspense>
        }
      />
      <Route path="/auth-error" element={<AuthError />} />
      <Route
        path="/open-on-device-with-fa-app"
        element={
          <Suspense fallback={<Loading />}>
            <OpenOnDeviceWithFaApp title="Sign in and send unique digital gifts!" />
          </Suspense>
        }
      />
      <Route
        path="/redeem-on-device-with-fa-app"
        element={
          <Suspense fallback={<Loading />}>
            <OpenOnDeviceWithFaApp />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<Loading />}>
            <App
              areSpecialOccasionsLive={areSpecialOccasionsLive}
              familyAlbumAutologinDeeplink={familyAlbumAutologinDeeplink}
              familyAlbumDownloadDeeplink={familyAlbumDownloadDeeplink}
              clarityProjectId={clarityProjectId}
              explanationVideoUrl={explanationVideoUrl}
            />
          </Suspense>
        }
      />
    </Routes>
  )
}

export default Root
